import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <p>404</p>
  </Layout>
)

export default NotFoundPage
